
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import { Global } from "@/support/GlobalData";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { SessionType } from "@/shared/enums/SessionType";
import App from "@/App.vue";

@Options({

})
export default class SelectCalibration extends PageBase {

    allowAssistedCalibration(): boolean {
        return Global.AllowAssistedCalibration;
    }
    allowAssistedTargetDisplay(): boolean {
        return Global.AllowAssistedTargetDisplay;
    }
    allowSelfCalibration(): boolean {
        return Global.AllowSelfCalibration;
    }

    onClickedAssist(): void {
        Global.SessionType = SessionType.AssistedCalibration;
        CalibrationEventSupport.sendEvent(CalibrationEvent.AssistedCalibrationSelectedByCompanion);
        this.loading = true;
    }
    onClickedAssistedTargetDisplay(): void {
        Global.SessionType = SessionType.AssistedTargetDisplay;
        CalibrationEventSupport.sendEvent(CalibrationEvent.AssistedTargetDisplaySelectedByCompanion);
        this.loading = true;
    }
    onClickedSelf(): void {
        this.loading = true;
        Global.SessionType = SessionType.SelfCalibration;
        CalibrationEventSupport.sendEvent(CalibrationEvent.SelfCalibrationSelectedByCompanion);
        this.loading = true;
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}

