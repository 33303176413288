

import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import BatteryInfoDto from "@/shared/models/BatteryInfoDto";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            less20WasShown: false,
            less10WasShown: false,
        };
    },
    props: {
        batteryLevel: { type: Number, required: true },
        charging: { type: Boolean, required: true },
    },
    methods: {
        getStatusCss(): string {
            if (this.batteryLevel == null) return ""; // nothing to report
            if (this.showLess10() || this.showLess20()) return "";// don't over-colorize, we have alerts showing
            if (this.batteryLevel > 10 && this.batteryLevel <= 20) return "bg-warning";
            if (this.batteryLevel <= 10) return "bg-danger text-white";
            return "";
        },
        showLess20(): boolean {
            if (this.batteryLevel === null) return false;// nothing to report
            if (this.charging) return false;// don't show
            if (this.less20WasShown) return false;// already shown
            return this.batteryLevel > 10 && this.batteryLevel <= 20;
        },
        showLess10(): boolean {
            if (this.batteryLevel === null) return false;// nothing to report
            if (this.charging) return false;// don't show
            if (this.less10WasShown) return false;// already shown
            return this.batteryLevel <= 10;
        },
        close20Clicked(): void {
            this.less20WasShown = true;
            CalibrationEventSupport.sendEvent(CalibrationEvent.BatteryLowAcknowledged20ByCompanion);
        },
        close10Clicked(): void {
            this.less20WasShown = this.less10WasShown = true;
            CalibrationEventSupport.sendEvent(CalibrationEvent.BatteryLowAcknowledged10ByCompanion);
        },
    },
    mounted(): void {
        SignalR.onBatteryInformation = (info: BatteryInfoDto): void => {
            Global.BatteryPercentageFull = info.PercentageFull;
            Global.BatteryExternalPower = info.ExternalPowerInd;
            Global.MainApp.updateHeader();
        };
        SignalR.onBatteryLowAcknowledged20 = (): void => {
            this.less20WasShown = true;
        };
        SignalR.onBatteryLowAcknowledged10 = (): void => {
            this.less20WasShown = this.less10WasShown = true;
        };
    },
    unmounted(): void {
        SignalR.onBatteryInformation = null;
        SignalR.onBatteryLowAcknowledged20 = null;
        SignalR.onBatteryLowAcknowledged10 = null;
    }
});

