

import { Global } from "@/support/GlobalData";
import Globals from "@/support/Globals";
import { Options, Vue } from "vue-class-component";

@Options({
})
export default class Header extends Vue {

    public static BatteryShown = true;

    deviceName(): string {
        return Global.DeviceName;
    }
    batteryShown(): boolean {
        return Header.BatteryShown &&  Global.BatteryPercentageFull != null;
    }
    batteryLevel(): number {
        return Global.BatteryPercentageFull || 0;
    }
    charging(): boolean {
        return Global.BatteryExternalPower || false;
    }

    environmentName = Globals.GetEnvironment();

    public update(): void {
        this.$forceUpdate();
    }
}
