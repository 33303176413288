
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";
import Header from "@/components/Header.vue";
import { Global } from "@/support/GlobalData";

@Options({
    HeaderMessage
})
export default class Home extends PageBase {

    mounted(): void {
        Header.BatteryShown = false;
        Global.MainApp.updateHeader();
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}
