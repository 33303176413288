
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import Header from "@/components/Header.vue";
import { Global } from "@/support/GlobalData";

@Options({
})
export default class PageNotFound extends PageBase {
    currentPage = window.location.toString();

    mounted(): void {
        Header.BatteryShown = false;
        Global.MainApp.updateHeader();
    }
}

